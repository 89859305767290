/**
 * This module handle the validations
 */
import { validationMixin } from 'vuelidate';

export default {
  mixins: [validationMixin],
  methods: {
    getValidationClass(fieldName: string): any {
      const field = (this as any).$v.model[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    }
  }
}