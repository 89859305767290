<template>
  <div class="strength-container">
    <div class="strength-bar">
      <div
        class="strength-meter"
        :style="{ width: entropyScore + '%', backgroundColor: barColor }"
      ></div>
    </div>
    <div class="strength-label">{{ strengthLabel }}</div>
  </div>
</template>

<script>
export default {
  props: ["password"],
  computed: {
    entropyScore() {
      const length = this.password.length;
      let characterPool = 0;

      if (/[a-z]/.test(this.password)) characterPool += 26;
      if (/[A-Z]/.test(this.password)) characterPool += 26;
      if (/[0-9]/.test(this.password)) characterPool += 10;
      if (/-/.test(this.password)) characterPool += 1;
      if (/\+/.test(this.password)) characterPool += 1;
      if (/[_]/.test(this.password)) characterPool += 1;
      if (/[\s]/.test(this.password)) characterPool += 1;
      if (/[!@#$%^&*(),.?":{}|<>()[\]{}]/.test(this.password))
        characterPool += 32;
      const entropy = length * Math.log2(characterPool || 1);
      const maxEntropy = 128;
      return Math.min((entropy / maxEntropy) * 100, 100);
    },
    barColor() {
      if (this.entropyScore <= 20) return "#FF4D4D";
      if (this.entropyScore <= 40) return "#FF7F50";
      if (this.entropyScore <= 60) return "#FFA500";
      if (this.entropyScore <= 80) return "#B2FF66";
      return "#66FF66";
    },
    strengthLabel() {
      if (this.entropyScore <= 20) return "Very Weak";
      if (this.entropyScore <= 40) return "Weak";
      if (this.entropyScore <= 60) return "Moderate";
      if (this.entropyScore <= 80) return "Strong";
      return "Very Strong";
    },
  },
};
</script>
<style scoped>
.strength-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.strength-bar {
  width: 100%;
  background-color: #e0e0e0;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}
.strength-meter {
  height: 100%;
  transition: width 0.3s;
}
.strength-label {
  margin-top: 5px;
  font-size: 14px;
  color: #473068;
  font-weight: bold;
}
</style>
